import {
	createStylesToHideElements,
	removeStylesToHideElements,
} from './handleStylesToAvoidFlickering';

import createReactAppScript from './createReactAppScript';
import cleanUpSelectors from './cleanUpSelectors';

export async function runScript(isIE) {
	try {
		cleanUpSelectors();
		createStylesToHideElements();

		await createReactAppScript(isIE);
	} catch (e) {
		removeStylesToHideElements();
		throw new Error(e);
	}
}
