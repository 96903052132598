import getCurrentScript from './getCurrentScript';

function getSelectorsArr(selectors = '') {
	return selectors.split(';')?.map((s) => s.trim());
}

function getUniqueSelectors(selectors = ['']) {
	return selectors
		?.filter(
			(element, index) => selectors.indexOf(element) === index && !!element
		)
		.join(';');
}

export default function cleanUpSelectors() {
	const currentScript = getCurrentScript();

	const selectors = getSelectorsArr(currentScript?.dataset?.selectors);
	const uniqueSelectors = getUniqueSelectors(selectors);

	currentScript.dataset.selectors = uniqueSelectors;

	return uniqueSelectors;
}
