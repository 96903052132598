import getCurrentScript from './getCurrentScript';

function getScriptData() {
	const currentScript = getCurrentScript();
	const selectors = currentScript?.dataset?.selectors?.split(';') || [];

	return {
		id: currentScript.id,
		selectors,
	};
}

export function createStylesToHideElements() {
	const { selectors, id } = getScriptData();

	selectors.forEach((selector) => {
		const style = document.createElement('style');
		style.textContent = `#${selector} { opacity: 0 !important; }`;
		style.id = `${id}-${selector}`;
		document.head.appendChild(style);
	});
}

export function removeStylesToHideElements() {
	const { selectors, id } = getScriptData();

	selectors.forEach((selector) => {
		const styleId = `${id}-${selector}`;
		const style = document.getElementById(styleId);

		if (style) style.remove();
	});
}
