import getCurrentScript from './getCurrentScript';

function loadScriptPromise(isIE) {
	const currentScript = getCurrentScript();

	return new Promise((resolve, reject) => {
		window.addEventListener('DOMContentLoaded', async () => {
			try {
				if (isIE) {
					const res = await import('../src/indexIe11');
					resolve(res);
				} else {
					const res = await import('../src/indexCommon');
					res.indexCommon(currentScript);
					resolve(res);
				}
			} catch (e) {
				reject(e);
			}
		});
	});
}

export default async function createReactAppScript(isIE) {
	await loadScriptPromise(isIE);
}
